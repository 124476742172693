<template>
    <div class="index">
        <navigationBar></navigationBar>
        <div style="height: 90px; width: 100%"></div>
        <div class="mian"><router-view /></div>
        <footerBar></footerBar>
        <footerAffix></footerAffix>
    </div>
</template>
<script>
import footerAffix from '@/components/footerAffix.vue'
export default {
    components: {
        footerAffix
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
<style lang="less">
</style>