<template>
    <div class="affix">
        <div class="affix-left" @mouseover="handleMouseoverAffix" @mouseleave="handleMouseliveAffix" :style="{width: (codeVisible || consultVisible) ? '540px' : '60px' }">
            <div class="consult boxshadow" v-show="consultVisible" :style="{ 'margin-top': !codeVisible ? '20px' : '0' }">
                <img class="img" src="@/assets/image/consult.png" />
                <el-divider style="height: 2em" direction="vertical"></el-divider>
                <div style="margin-left: 10px">
                    <a href="http://wpa.qq.com/msgrd?v=3&uin=3313744758&site=qq&menu=yes" rel="noopener" target="view_window" style="cursor: pointer; color: #000">售前在线咨询</a>
                    <P style="font-size: 14px; color: #999999">专属客服经理在线解答，欢迎咨询</P>
                </div>
            </div>
            <div class="code boxshadow" :style="{ 'margin-top': !consultVisible ? '70px' : '0' }" v-show="codeVisible">
                <div class="codebox">
                    <img src="@/assets/image/code1.png" />
                    <P>安卓</P>
                </div>
                <div class="box-border"></div>
                <div class="codebox">
                    <img src="@/assets/image/code2.png" />
                    <P>IOS</P>
                </div>
                <div class="box-border"></div>
                <div class="codebox">
                    <img src="@/assets/image/code3.png" />
                    <P>小程序</P>
                </div>
            </div>
        </div>
        <div class="affix-right boxshadow">
            <div class="right-box" @click="handleChat" @mouseover="handleMouseOverChat('chat')" @mouseleave="handleMouseleaveChat('chat')">
                <img src="@/assets/image/客服.png" />
                <p>咨 询<i></i></p>
            </div>
            <el-divider style="margin: 33px" direction="horizontal"></el-divider>
            <div class="right-box" @mouseover="handleMouseOverChat('code')" @mouseleave="handleMouseleaveChat('code')">
                <img src="@/assets/image/二维码.png" />
                <p>下 载<i></i></p>
            </div>
            <el-divider style="margin: 33px" direction="horizontal"></el-divider>
            <div class="right-box" @click="handleBack">
                <img src="@/assets/image/箭头.png" />
                <p>返 回<i></i></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consultVisible: false,
            codeVisible: false,
            isVisible: false,
            codeList: [
                {
                    url: require('../assets/image/code1.png'),
                    name: '安卓',
                },
                {
                    url: require('../assets/image/code2.png'),
                    name: 'IOS',
                },
                {
                    url: require('../assets/image/code3.png'),
                    name: '小程序',
                },
            ],
        }
    },
    methods: {
        handleChat() {
            this.consultVisible = !this.consultVisible
        },
        handleMouseOverChat(val) {
            if (val === 'chat') {
                this.consultVisible = true
            } else {
                this.codeVisible = true
            }
        },
        handleMouseleaveChat(val) {
            if (val === 'chat') {
                setTimeout(() => {
                if (this.isVisible) {
                    this.consultVisible = true
                } else {
                    this.consultVisible = false
                }
            },100)
            } else {
                setTimeout(() => {
                if (this.isVisible) {
                    this.codeVisible = true
                } else {
                    this.codeVisible = false
                }
            },100)
            }
        },
        handleMouseoverAffix() {
            this.isVisible = true
        },
        handleMouseliveAffix() {
            this.isVisible = false
            this.consultVisible = false
            this.codeVisible = false
        },
        handleCode() {
            this.codeVisible = !this.codeVisible
        },
        handleBack() {
            let scrollHeight = document.documentElement.scrollTop
            let timer = setInterval(() => {
                scrollHeight -= 10
                document.documentElement.scrollTop = scrollHeight
                if (scrollHeight <= 0) {
                    clearInterval(timer)
                }
            })
        },
    },
}
</script>

<style lang="less">
.affix {
    display: flex;
    position: fixed;
    z-index: 10000;
    bottom: 10px;
    right: 14px;
    align-items: center;
    .affix-left {
        width: 540px;
        height: 300px;
        padding-right: 17px;
        .consult {
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            width: 360px;
            height: 100px;
            border-radius: 10px;
            margin-bottom: 8px;
            color: #000000;
            font-size: 20px;
            .img {
                margin: 0 10px;
            }
        }
        .code {
            display: flex;
            // justify-content: space-around;
            align-items: center;
            width: 540px;
            height: 180px;
            border-radius: 10px;
            .codebox {
                display: inline-block;
                text-align: center;
                width: 117px;
                height: 140px;
                margin: 0 10px 0 31px;
                img {
                    width: 100%;
                    height: 117px;
                }
            }
            .box-border {
                border-right: 1px solid #e6e6e6;
                height: 92px;
                width: 1px;
                margin-left: 20px;
            }
        }
    }
    .boxshadow {
        box-shadow: 0 0 10px #93b7d7;
        background: #ffffff;
    }
    .affix-right {
        width: 60px;
        height: 232px;
        border-radius: 50px;
        float: right;
        font-size: 8px;
        text-align: center;
        .right-box {
            height: 40px;
            width: 36px;
            margin: 16px auto;
            cursor: pointer;
            p {
                text-align: justify;
                height: 10px;
            }
            i {
                display: inline-block;
                width: 100%;
            }
        }
        .el-divider {
            margin: 0 auto;
            width: 45%;
        }
    }
}
</style>